<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <h2 class="text-center">Sunny Ronaldo Company</h2>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form v-model="formValid" :disabled="processing" @submit.prevent="formValid && submitLogin()">
            <v-text-field
              v-model="username"
              class="mb-2"
              outlined
              label="帳號"
              :rules="[v => !!v || '請輸入帳號']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密碼"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[v => !!v || '請輸入密碼']"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              depressed
              :loading="processing"
              :disabled="!formValid"
              block
              elevation="0"
              color="primary"
              class="mt-6"
              type="submit"
              @click="formValid && submitLogin()"
            >
              登入
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar v-model="loginError" timeout="4000" color="error" top>
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="white">
          {{ icons.mdiAlertOctagon }}
        </v-icon>
        帳號或密碼錯誤
      </div>
      <template #action="{ attrs }">
        <v-btn depressed color="white" icon v-bind="attrs" @click="loginError = false">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiClose, mdiAlertOctagon } from '@mdi/js'
import { defaultPage } from '@/assets/cannotAccessList'

export default {
  name: 'Login',
  // data
  data: () => ({
    processing: false,
    formValid: false,
    loginError: false,
    isPasswordVisible: false,
    username: '',
    password: '',
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiClose,
      mdiAlertOctagon,
    },
  }),

  // functions
  methods: {
    async submitLogin() {
      if (!this.processing) {
        this.processing = true

        const payload = {
          username: this.username,
          password: this.password,
        }
        try {
          const data = await this.$XHR.api('cms_admin_login', payload)
          this.$func.log('---login---')
          this.$func.log(data)

          this.setUserData({
            admin_type: data.admin_type,
            id: data.id,
            last_login: data.last_login,
            username: data.username,
            verify_token: data.verify_token,
          })

          this.$router.replace(defaultPage[data.admin_type])
        } catch (error) {
          this.$func.log(error)
          this.loginError = true
          this.processing = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
